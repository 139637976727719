import '../css/Invite.css';


import { useTranslation } from 'react-i18next';

export function Invite({onTry,bgcolor}) {

    const { t } = useTranslation();
    function onExperience() {
        onTry()
    }

    return (
        <div className="InviteBg" style={{backgroundColor: `${bgcolor}`}}>
            <div className='InviteBg-left'>
                <p>{t('home.solution')}</p>
                <span>{t('home.safe')}</span>
                <button className='btn' onClick={onExperience}>{t('home.btn')}</button>
            </div>
        </div>
    )
};


export default Invite;