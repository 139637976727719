
import '../css/Rebate.css';
import lxs from '../assets/lxs.png';
import sjmt from '../assets/sjmt.png';
import hrqt from '../assets/hrqt.png';
import hwhr from '../assets/hwhr.png';
import zqyj from '../assets/zqyj.png';
import sxzm from '../assets/sxzm.png';
import geyj from '../assets/geyj.png';
import gshy from '../assets/gshy.png';
import zyd from '../assets/zyd.png';
import { useTranslation } from 'react-i18next';

function Rebate({ onDownload }) {

    const { t } = useTranslation();

    const list = [
        {
            src: lxs,
            content: t('rebate.student')
        },
        {
            src: sjmt,
            content: t('rebate.possesses')
        },
        {
            src: hrqt,
            content: t('rebate.workenviron')
        },
        {
            src: hwhr,
            content: t('rebate.living')
        },
        {
            src: zqyj,
            content: t('rebate.studywork')
        },
        {
            src: sxzm,
            content: t('rebate.certificate')
        }
    ]

    const detailsList = [
        {
            src: geyj,
            title: t('rebate.detailsList.geyj.title'),
            content: t('rebate.detailsList.geyj.content'),
        },
        {
            src: gshy,
            title: t('rebate.detailsList.gshy.title'),
            content: t('rebate.detailsList.gshy.content'),
        },
        {
            src: zyd,
            title: t('rebate.detailsList.zyd.title'),
            content: t('rebate.detailsList.zyd.content'),
        }
    ]

    function onRegister() {
        onDownload()
    }

    return (
        <div className="Rebate">
            <div className='acting'>
                <div className='right'>
                    <p style={{ marginTop: "98px" }}>{t('rebate.agent')}</p>
                    <span>{t('rebate.simole')}</span>
                    <button onClick={onRegister}>{t('rebate.signup')}</button>
                </div>
            </div>

            <div className='rebate'>
                <p>{t('rebate.need')}</p>
                <div className='rebate-list'>
                    {
                        list.map((item, index) => {
                            return (
                                <div className='rebate-list-item' key={index}>
                                    <img src={item.src} alt=""></img>
                                    <span>{item.content}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='details'>
                {
                    detailsList.map((item, index) => {
                        return (
                            <div className='details-item' style={{ backgroundColor: `${index % 2 === 0 ? '#fff' : 'rgb(247 248 255)'}` }} key={index}>
                                <div className='details-item-left'>
                                    <p className='details-item-title'>{item.title}</p>
                                    <p className='details-item-content'>{item.content}</p>
                                </div>
                                <img src={item.src} alt=""></img>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Rebate;