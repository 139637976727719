import React, { useState, useEffect } from "react";
import '../css/Connect.css';
import email from '../assets/email.png'
import group from '../assets/group.png'
import twitter from '../assets/twitter.png'
import bg from '../assets/bg4.png'
import zsgs from '../assets/zsgs.png'
// import nrjs from '../assets/nrjs.png'
import zzjs from '../assets/zzjs.png'
import tyh from '../assets/tyh.png'
import xy from '../assets/xy.png'
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import service from "../utils/api";


function Connect() {
    const { t } = useTranslation();
    const [addressArr, setAddressArr] = useState([])
    useEffect(() => {
        if (global.address && global.address.data) {
            setAddressArr(global.address.data)
        } else {
            service.post('/guanwang/contact')
                .then(res => {
                    const addressList = res.data.data || []
                    setAddressArr(addressList)
                })
        }
    }, [])

    const connectList = [
        {
            img: email,
            name: t('bottom.email'),
            url: addressArr.email,
            content: t('bottom.copy')
        },
        {
            img: group,
            name: t('bottom.group'),
            url: addressArr.tg,
            content: t('bottom.joins')
        },
        {
            img: twitter,
            name: t('bottom.twetter'),
            url: addressArr.twitter,
            content: t('bottom.follow')
        },
    ]

    function toConnect(index) {
        if (index === 1) {
            window.open(addressArr.tg)
        } else if (index === 2) {
            window.open(addressArr.twitter)
        } else {
            if (copy(addressArr.email)) {
                message.info(t('content.copySuccess'), 1);
            }
        }

    }

    return (
        <div className="Connect">
            <p>{t('bottom.contactUs')}</p>
            <div className='ContactList'>
                {
                    connectList.map((item, index) => {
                        return (
                            <div className='listItem' key={index} onClick={() => toConnect(index)}>
                                <img src={item.img} alt=""></img>
                                <p>{item.name}</p>
                                <span>{item.url}</span>
                                <button>{item.content}</button>
                            </div>
                        )
                    })
                }
            </div>
            <div className='introduce'>
                <p>{t('content.introduce')}</p>
                <span>{t('content.info')}</span>
            </div>
            <img className='map' src={bg} alt=''></img>
            <div className='content'>
                <div className='content-item'>
                    <div className='content-item-info'>
                        <p className='content-item-info-p'>{t('content.zsgs.title')}</p>
                        <span className='content-item-info-span'>{t('content.zsgs.content')}</span>
                    </div>
                    <img src={zsgs} alt="" className='content-item-img' ></img>
                </div>
                <div className='content-item2'>
                    <img src={zsgs} alt="" className='content-item-img'></img>
                    <div className='content-item-info2' >
                        <p className='content-item-info-p'>{t('content.nrjs.title')}</p>
                        <span className='content-item-info-span'>{t('content.nrjs.content')}</span>
                    </div>

                </div>
                <div className='content-item' >
                    <div className='content-item-info'>
                        <p className='content-item-info-p'>{t('content.zzjs.title')}</p>
                        <span className='content-item-info-span'>{t('content.zzjs.content')}</span>
                    </div>
                    <img src={zzjs} alt="" className='content-item-img' ></img>
                </div>
                <div className='content-item2'>
                    <img src={tyh} alt="" className='content-item-img' ></img>
                    <div className='content-item-info2'>
                        <p className='content-item-info-p'>{t('content.tyh.title')}</p>
                        <span className='content-item-info-span'>{t('content.tyh.content')}</span>
                    </div>

                </div>
                <div className='content-item'>
                    <div className='content-item-info'>
                        <p className='content-item-info-p'>{t('content.xy.title')}</p>
                        <span className='content-item-info-span'>{t('content.xy.content')}</span>
                    </div>
                    <img src={xy} alt="" className='content-item-img'></img>
                </div>
            </div>

        </div>
    )
}

export default Connect;