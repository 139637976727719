
import H5top from './components/H5top.js';
import H5home from './components/H5home.js';
import H5bottom from './components/H5bottom.js';
import H5dwonload from './components/H5dwonload.js';
import H5rebate from './components/H5rebate.js';
import H5protocolPolicy from './components/H5protocolPolicy.js';
import H5member from './components/H5member.js';
import H5sidebar from './components/H5sidebar.js';
import H5Account from './components/H5Account.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


function H5() {
    document.documentElement.scrollTop = 0
    const { t, i18n } = useTranslation();

    const [pageIndex, setPageIndex] = useState(0)
    const [languageCode, setLanguageCode] = useState('EN')
    // const [name, setNmae] = useState("iOS AppStore")

    useEffect(() => {
        if (navigator.language === 'zh-CN' || navigator.language === 'zh-SG' || navigator.language === 'zh') {
            setLanguageCode('CN')
        } else if (navigator.language === 'zh-TW' || navigator.language === 'zh-HK' || navigator.language === 'zh-MO' || navigator.language === 'zh-CHT') {
            setLanguageCode('TW')
        }
    }, [])

    // 打开页面
    function onPage(index) {
        setPageIndex(index)
    }

    //修改语言
    function onLanguage(code) {
        setLanguageCode(code)
        i18n.changeLanguage(code)
    }

    //打开侧边栏
    function handleOpenSlidebar() {
        setPageIndex(10)
    }

    //打开下载页面
    function handleDownload(name) {
        // setNmae(name)
        setPageIndex(2)
    }
    return (
        <div>
            <H5top onOpenSidebar={handleOpenSlidebar} code={languageCode}></H5top>
            <div style={{ height: "48px" }}></div>
            {
                (() => {
                    if (pageIndex === 0) {
                        return (
                            <H5home onPage={() => { setPageIndex(2) }}></H5home>
                        )
                    } else if (pageIndex === 1) {
                        return (
                            <H5member></H5member>
                        )
                    } else if (pageIndex === 2) {
                        return (
                            <H5dwonload></H5dwonload>
                        )
                    } else if (pageIndex === 3) {
                        return (
                            <H5rebate onDownload={() => { setPageIndex(2) }}></H5rebate>
                        )
                    } else if (pageIndex === 8 || pageIndex === 9) {
                        return (
                            <H5protocolPolicy title={pageIndex === 8 ? t('bottom.privacys') : t('bottom.service')} content={pageIndex === 8 ? t('illustrate.privacyPolicy') : t('illustrate.serviceAgreement')}></H5protocolPolicy>
                        )
                    } else if (pageIndex === 10) {
                        return (
                            <H5sidebar onPageItem={onPage} onChangeLanguage={onLanguage}></H5sidebar>
                        )
                    } else if (pageIndex === 6) {
                        return (
                            <H5Account onH5Account={onPage}></H5Account>
                        )
                    }
                })()
            }
            {
                (() => {
                    if (pageIndex < 8) {
                        return (
                            <H5bottom onDownload={handleDownload} onOpen={onPage}></H5bottom>
                        )
                    }
                })()
            }


        </div>
    )
}
export default H5