import '../css/H5rebate.css';
import lxs from '../assets/lxs.png';
import sjmt from '../assets/sjmt.png';
import hrqt from '../assets/hrqt.png';
import hwhr from '../assets/hwhr.png';
import zqyj from '../assets/zqyj.png';
import sxzm from '../assets/sxzm.png';
import geyj from '../assets/geyj.png';
import gshy from '../assets/gshy.png';
import zyd from '../assets/zyd.png';

import { useTranslation} from 'react-i18next';
function H5rebate({onDownload}) {
    const { t } = useTranslation();
    const list = [
        {
            src: lxs,
            content: t('rebate.student')
        },
        {
            src: sjmt,
            content: t('rebate.possesses')
        },
        {
            src: hrqt,
            content: t('rebate.workenviron')
        },
        {
            src: hwhr,
            content: t('rebate.living')
        },
        {
            src: zqyj,
            content: t('rebate.studywork')
        },
        {
            src: sxzm,
            content: t('rebate.certificate')
        }
    ]

    const detailsList = [
        {
            src: geyj,
            title:t('rebate.detailsList.geyj.title'),
            content: t('rebate.detailsList.geyj.content'),
        },
        {
            src: gshy,
            title:t('rebate.detailsList.gshy.title'),
            content: t('rebate.detailsList.gshy.content'),
        },
        {
            src: zyd,
            title:t('rebate.detailsList.zyd.title'),
            content: t('rebate.detailsList.zyd.content'),
        }
    ]

    function toDownload(){
        onDownload()
    }

    return (
        <div className="H5rebate">
            <div className='H5rebate-top'>
                <p>{t('rebate.agent')}</p>
                <span>{t('rebate.simole')}</span>            
            </div>
            <button onClick={toDownload}>{t('rebate.signup')}</button>
            <div className='title'>{t('rebate.need')}</div>
            <div className='u-list'>
                {
                    list.map((item,index)=>{
                        return(
                            <div className='u-list-item' key={index}>
                                <img src={item.src} alt=''></img>
                                <p>{item.content}</p>
                            </div>
                        )
                    })
                }
            </div>

            <div className='details-list'>
                {
                    detailsList.map((item,index)=>{
                        return(
                            <div className='details-list-item' key={index}> 
                                <p>{item.title}</p>
                                <img src={item.src} alt=''></img>
                                <div className='details-content'>{item.content}</div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default H5rebate