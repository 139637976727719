import { useState, useEffect } from 'react';
import '../css/Download.css';
import selectIcon from '../assets/select-icon.png';
import ios from '../assets/ios.png';
import andriod from '../assets/andriod.png'
import macos from '../assets/macOS.png'
import tv from '../assets/tv.png'
import windows from '../assets/windows.png'
import jszx from '../assets/zksd.png';
import bxs from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import wsts from '../assets/ddts.png';
import mgg from '../assets/mgg.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';
import service from '../utils/api';
function Download() {

    const platform = global.platformOS;
    const { t, i18n } = useTranslation();
    const [storeName, setStoreName] = useState(platform?.storeName ? platform?.storeName : "iOS AppStore")
    const [type, setType] = useState(platform?.name ? platform?.name : 'ios')
    const downloadTips = t('download.downloadtips')

    const [urlList, seturlList] = useState([])

    useEffect(() => {
        if (global.urlList && global.urlList.data) {
            seturlList(global.urlList.data)
        } else {
            service.post('/guanwang/version')
                .then(res => {
                    const lists = res.data.data || []
                    seturlList(lists)
                })
        }
    }, [])

    const infoList = [
        {
            content: t('download.fast')
        },
        {
            content: t('download.safe')
        },
        {
            content: t('download.unlimite')
        },
        {
            content: t('download.free')
        },
        {
            content: t('download.invite')
        }
    ]

    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName: "iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName: "Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName: "Mac "
        },
        {
            img: windows,
            name: "Windows",
            storeName: "Windows Application"
        },
        {
            img: tv,
            name: "TV",
            storeName: "TV Application"
        }

    ]

    const vipList = [
        {
            img: jszx,
            content: t('download.accele')
        },
        {
            img: bxs,
            content: t('download.unspeed')
        },
        {
            img: ddts,
            content: t('download.multi')
        },
        {
            img: wsts,
            content: t('download.speed')
        },
        {
            img: mgg,
            content: t('download.noad')
        }
    ]

    const clientList = [
        {
            img: step1,
            content: t('download.step1')
        },
        {
            img: step2,
            content: t('download.step2')
        },
        {
            img: step3,
            content: t('download.step3')
        }

    ]


    function onDownload() {
        let data = ''
        for (const ite of urlList) {
            if (type.toLowerCase() === ite.version_type.toLowerCase()) {
                data = ite
            }
        }

        if (data.version_down_url) {
            window.open(data.version_down_url, "_blank")
        } else {
            message.info(data ? data.version_tips : t('download.tips'), 1);
        }
    }

    function toDownload(item) {
        setStoreName(item.storeName)
        setType(item.name)
    }

    const imgs = { 'zh-CN': require('../assets/googleplay/googlePlayBadge-zh.png'), "en": require('../assets/googleplay/googlePlayBadge-en.png'), "zh-TW": require('../assets/googleplay/googlePlayBadge-tw.png') }
    
    return (
        <div className='Download'>
            <div className='top-layout'>
                <div className='Download-right'>
                    <p>Zenon VPN</p>
                    <span>{t('download.available')}</span>
                    {
                        infoList.map((item, index) => {
                            return (
                                <div className='info-item' key={index}>
                                    <img src={selectIcon} alt=""></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                    {type === "Android" ?
                        <div className="google-play-container"><a href="https://play.google.com/store/apps/details?id=com.zenon.android
                        "><img className="google-play-icon" src={imgs[i18n.language]} alt=''></img></a><div className="text-download" onClick={onDownload}>
                            {t('download.googlePlay')}
                        </div></div> : <button onClick={onDownload} className={type}>{storeName} {t('slidebar.download')}</button>
                    }
                </div>

            </div>
            <div className='download-layout'>

                <div className='downloadlist'>
                    {
                        downloadList.map((item, index) => {
                            return (
                                <div className='download-item' key={index} onClick={() => toDownload(item)}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <p>{downloadTips}</p>
            </div>

            <div className='viplayout'>
                <p>{t('download.joinvip')}</p>
                <div className='viplist2'>
                    {
                        vipList.map((item, index) => {
                            return (
                                <div style={{ marginLeft: index >= 3 ? '180px' : '' }} className='vip-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.content}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='step-layput'>
                <p>{t('download.how')}</p>
                <span>{t('download.keep3step')}</span>
                <div className='viplist'>
                    {
                        clientList.map((item, index) => {
                            return (
                                <div className='vip-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <div className='step-count'>{index + 1}</div>
                                    <span style={{ width: '180px' }}>{item.content}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

export default Download