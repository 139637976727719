import React, { useState, useEffect } from "react";
import '../css/H5member.css';
import alipay from '../assets/alipay.png';
import wechat from '../assets/wechat.png';
import visa from '../assets/visa.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import I18n from '../locale/I18n.js';
import { useTranslation } from 'react-i18next';
import service from '../utils/api';
import { getOperationSys } from "..";

function H5member() {

    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [memberList, setMemberList] = useState([]);
    const [swiperRef, setSwiperRef] = useState(null);

    useEffect(() => {
        const oauthType = getOperationSys();
        service.post('/guanwang/package', { oauth_type: oauthType, lang: I18n.language }).then(res => {
            const list = res.data.data || []
            setMemberList(list)
        })
    }, [])

    const payList = [
        {
            img: alipay,
            name: t('member.ali')
        },
        {
            img: wechat,
            name: t('member.wechat')
        },
        {
            img: visa,
            name: "VISA"
        }
    ]

    const slideTo = (index) => {
        swiperRef.slideTo(index, 500);
    }


    return (

        <div className='H5member'>

            <div className='member-content'>
                <p style={{ lineHeight: '76px' }}>{t('member.memberPrice')}</p>
                <Swiper
                    onSwiper={setSwiperRef}
                    spaceBetween={10}
                    slidesPerView={2}
                    centeredSlides={true}
                    onSlideChange={(e) => {
                        setCurrentIndex(e.realIndex)
                    }}
                >
                    {
                        memberList.map((item, index) => {

                            return (
                                <SwiperSlide style={{ width: '166px', height: '220px' }} key={index} onClick={()=>slideTo(index)}>
                                    <div className={index === currentIndex ? "h5recom" : "h5recom-no"} >
                                        <div className={item.pack_activity_pic ? 'h5-recom-txt' : ''}> {item.pack_activity_pic ? item.pack_activity_pic : ''}</div>
                                    </div>
                                    <div className={index === currentIndex ? "swiper-slide-select" : "swiper-slide-normal"}>
                                        <p style={{ marginTop: "20px" }}>{item.pack_name}</p>
                                        <p style={{ color: "rgb(27 28 29)", fontSize: "18px" }}>$<span style={{ fontSize: "35px" }}>{item.pack_price}</span></p>
                                        <p style={{ fontWeight: '300', textDecoration: 'line-through', color: 'rgb(95 95 115)' }}>${item.original_price}</p>
                                        <div className='discount-bg'>{item.pack_describe}  </div>

                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
            </div>
            <div className='pay-layout'>
                <p style={{ fontSize: '16px', color: 'rgba(27,28,29,0.8)' }}>{t('member.payfunc')}</p>
                <div className='pay-list'>
                    {
                        payList.map((item, index) => {
                            return (
                                <div className='pay-list-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

        </div>
    )
}

export default H5member