import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import '../css/H5bottom.css';
import ios from '../assets/ios.png'
import andriod from '../assets/andriod.png'
import macos from '../assets/macOS.png'
import tv from '../assets/tv.png'
import windows from '../assets/windows.png'
import email from '../assets/email.png'
import group from '../assets/tg.png'
import twitter from '../assets/twitter.png'
import service from '../utils/api';

function H5bottom({ onDownload, onOpen }) {
    const { t, i18n } = useTranslation();
    const [addressArr, setAddress] = useState([])
    useEffect(() => {
        service.post("/guanwang/contact")
            .then(res => {
                setAddress(res.data.data)
            })
    }, [])

    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName: "iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName: "Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName: "Mac Application"
        },
        {
            img: windows,
            name: "Windows",
            storeName: "Windows Application"
        },
        {
            img: tv,
            name: "TV",
            storeName: "TV Application"
        }

    ]

    const connectList = [
        {
            img: email,
            name: t('bottom.email'),
            url: addressArr.email,
            content: t('bottom.copy')
        },
        {
            img: group,
            name: t('bottom.group'),
            url: addressArr.tg,
            content: t('bottom.joins')
        },
        {
            img: twitter,
            name: t('bottom.twetter'),
            url: addressArr.twitter,
            content: t('bottom.follow')
        },
    ]

    function onTxt(index) {

        console.log(index)
        switch (index) {
            case 6:
                onOpen(index)
                break
            case 9:
                window.open('./illustrate.html?lang=' + i18n.language + '&index=' + 2)
                break
            case 8:
                window.open('./illustrate.html?lang=' + i18n.language + '&index=' + 3)
                break
            default:
                return
        }

    }

    function toDownload(name) {
        onDownload(name)
    }


    function toConnect(index) {
        if (index === 1) {
            window.open(addressArr.tg)
        } else if (index === 2) {
            window.open(addressArr.twitter)
        } else {
            if (copy(addressArr.email)) {
                message.info(t('content.copySuccess'), 1);
            }
        }
    }

    return (
        <div className='H5bottom'>
            <div className='downlist'>
                {
                    downloadList.map((item, index) => {
                        return (
                            <div className='downlist-item' key={index} onClick={() => toDownload(item)}>
                                <img src={item.img} alt=''></img>
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                }
            </div>

            <div className='connect-txt'>{t('bottom.contactUs')}</div>

            <div className='connect-list'>

                {
                    connectList.map((item, index) => {
                        return (
                            <div className='connect-list-item' key={index} onClick={() => toConnect(index)}>
                                <img src={item.img} alt=''></img>
                                <p>{item.name}</p>
                                <span>{item.url}</span>
                                <button>{item.content}</button>
                                {/* <p style={{ fontSize: "9px" }}>{item.content}</p> */}
                            </div>
                        )
                    })
                }
            </div>
            <div className='bottom'>
                <div className='txt-btn'>
                    <p onClick={() => onTxt(8)}>{t('bottom.privacy')}</p>
                    <p onClick={() => onTxt(9)}>{t('bottom.service')}</p>
                    <p onClick={() => onTxt(6)}> · {t('bottom.manage')}</p>
                </div>

                <p className='noinfo'>{t('bottom.info1')}</p>
            </div>

        </div>
    )
}

export default H5bottom