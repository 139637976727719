import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import '../css/Top.css';
import { Tabs } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png'
import unselect from '../assets/un-check.png';
import select from '../assets/check.png';

import { useTranslation } from 'react-i18next';
import service from "../utils/api";
import { getOperationSys } from "..";

const languageList = [
    {
        name: "简体中文(CN)",
        code: "zh-CN"
    },
    {
        name: "繁體中文(TW)",
        code: "zh-TW"
    },
    {
        name: "English(EN)",
        code: "en"
    }
]

const Top = forwardRef(({
    onChangeTab, onChangeLang
}, ref) => {
    useImperativeHandle(ref, (newval) => ({
        changeTab: (newval) => {
            handleTab(newval)
        }
    }))

    const { t, i18n } = useTranslation();

    const items = [
        {
            key: '1',
            label: t('slidebar.home'),
        },
        {
            key: '2',
            label: t('slidebar.member'),
        },
        {
            key: '3',
            label: t('slidebar.download'),
        },
        {
            key: '4',
            label: t('slidebar.rebate'),
        },
    ];

    const [isShow, setIsShow] = useState(false);
    const [language, setLanguage] = useState(languageList[2].name);
    const [code, setCode] = useState(languageList[0].code);
    const [activeKey, setActiveKey] = useState('1')

    useEffect(() => {
        languageList.map((item, index) => {
            console.log(i18n.language)
            console.log("i18n.language")
            if (item.code === i18n.language) {
                setCode(item.code)
                setLanguage(item.name)
            }
            return null;
        })
    }, [i18n])

    function handleTab(e) {
        console.log("handleTab>>>>>>>.", e)
        setActiveKey(e)
        onChangeTab(e)
    }

    function onLanguage() {
        console.log("onLanguage>>>>>>",language)
        setIsShow(!isShow)
    }

    function onChangeLanguae(item) {
        i18n.changeLanguage(item.code)
        setLanguage(item.name)
        setCode(item.code)
        setIsShow(false)
        const oauthType = getOperationSys();
        service.post("/guanwang/package", { oauth_type: oauthType, lang: i18n.language })
            .then(res => {
                onChangeLang(res.data.data)
            })
    }


    return (
        <div className="Top">
            <div className='top-left'>
                <img src={logo} alt='' />
                <Tabs defaultActiveKey='1' activeKey={activeKey} tabBarGutter={32} items={items} onTabClick={handleTab} />
            </div>


            <div className="line"></div>
            {/* <a style={{color:"#fff",fontSize:"20px"}} href="./assets/html/customer.html" target={}>测试</a> */}
            <div className='language'>
                <div style={{ display: "flex", alignItems: "center" }} onClick={onLanguage}>
                    <CaretDownOutlined style={{ color: "#fff" }} />
                    <span style={{ display: "block", width: "120px" }}>{language}</span>
                </div>

                {
                    isShow ? <div className='languageBg'>
                        {
                            languageList.map((item, index) => {
                                return (
                                    <div className={code === item.code ? 'language-item-active' : 'language-item'} onClick={() => onChangeLanguae(item)} key={index}>
                                        <img src={code === item.code ? select : unselect} alt=""></img>
                                        <p >{item.name}</p>
                                    </div>

                                )

                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    );
})

export default Top;