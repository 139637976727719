import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import H5 from './H5';
import I18n from './locale/I18n.js';
import service from './utils/api';


getPlatform();
postInformation();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  window.innerWidth < 620 ? <H5 /> : <App />
);

// console.log(I18n.language)

function getPlatform() {
  const userAgent = navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(userAgent) && !window.MSStream;
  const isMacOS = /Mac/.test(userAgent);
  const isWindows = /Win/.test(userAgent);
  const isTV = /TV/.test(userAgent);

  if (isIOS) {
    // 处理iOS设备逻辑
    global.platformOS = {
      name: "iOS",
      storeName: "iOS AppStore"
    }
  } else if (isAndroid) {
    // 处理Android设备逻辑
    global.platformOS = {
      name: "Android",
      storeName: "Android APK"
    }
  } else if (isMacOS) {
    // 处理macOS设备逻辑
    global.platformOS = {
      name: "MacOS",
      storeName: "Mac Application"
    }
  } else if (isWindows) {
    // 处理Windows设备逻辑
    global.platformOS = {
      name: "Windows",
      storeName: "Windows Application"
    }
  } else if (isTV) {
    // 处理TV设备逻辑
    global.platformOS = {
      name: "TV",
      storeName: "TV Application"
    }
  } else {
    // 处理其他设备逻辑
    global.platformOS = undefined;
  }
}

export function getOperationSys() {

  var oauthType;
  var agent = navigator.userAgent.toLowerCase();

  let isIos = /iphone/i.test(agent)
  let isAndriod = /android/i.test(agent)
  let isMac = /macintosh|mac os x/i.test(agent)
  let isWin = /windows|win32/i.test(agent);
  let ispad = /iPad/i.test(agent);
  if (isIos || ispad) {
    oauthType = 'ios'
  } else if (isAndriod) {
    oauthType = 'android'
  } else if (isMac) {
    oauthType = 'web'
  } else if (isWin) {
    oauthType = 'web'
  }

  global.oauthType = oauthType;

  return oauthType;
}

function postInformation() {
  const oauthType = getOperationSys();
  service.post('/guanwang/package', { oauth_type: oauthType, lang: I18n.language }).then(res => {
    global.member = {
      data: res.data.data
    }
  })


  service.post('/guanwang/version')
    .then(res => {
      global.urlList = {
        data: res.data.data
      }
    })

  service.post('/guanwang/contact')
    .then(res => {
      global.address = {
        data: res.data.data
      }
    })
}

getIP()

function reqAffLog(aff, ip) {
  service.post('/guanwang/affLog', {
    params: {
      aff: aff, ip: ip
    }
  })
    .then(res => {
      console.log(res, "reqAffLog>>>>>>>>>>>")
    })
}

function getIP() {
  const urlParams = new URLSearchParams(window.location.search);
  const aff = urlParams.get('aff'); // 获取 aff 参数的值
  console.log(aff, "affLog>>>>>>>")
  if (aff) {
    service.post('/guanwang/getIp')
      .then(res => {
        console.log(res.data.data.ip, "ip>>>>>>>")
        reqAffLog(aff, res.data.data.ip)
      })
  }

}


