import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
const i18nInstance = i18next.createInstance()
i18nInstance.use(initReactI18next);
console.log(navigator.language)
const locales = {
    "en-US": require('./en-US.json'),
    "zh-CN": require('./zh-CN.json'),
    "zh-TW": require('./zh-TW.json'),
  };
//   console.log(locales['en-US'])

const resources = {
    'en': {
        translation: locales['en-US'],
        name: "English(EN)",
        code: "en"
    },
    'zh-CN': {
        translation: locales['zh-CN'],
        name: "简体中文(CN)",
        code: "zh-CN"
    },
    'zh-TW': {
        translation: locales['zh-TW'],
        name: "繁體中文(TW)",
        code: "zh-TW"
    },
}

i18nInstance.init(
    {
        lng: "en",
        // 后备语言，如果用户语言的翻译不可用时使用此顺序
        fallbackLng: ["zh-CN", "en"],
        // 如果需要调试可以打开
        // debug: process.env.NODE_ENV === "development",
        ns: ["translation"],
        // 默认值就是 translation，可以进行设置
        defaultNS: "translation",
        resources: resources,
    }
)
if(navigator.language === 'en' || navigator.language === 'zh-CN'|| navigator.language === 'zh-TW'|| navigator.language === 'zh'){
    if(navigator.language === 'zh'){
        i18nInstance.changeLanguage('zh-CN')
    }else{
        i18nInstance.changeLanguage(navigator.language)
    }
   
}


export default i18nInstance;
