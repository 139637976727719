
import React, { useMemo, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import '../css/Member.css';
import I18n from '../locale/I18n.js';
// import axios from 'axios';

import { useTranslation } from 'react-i18next';
import service from '../utils/api';
import { getOperationSys } from '..';
SwiperCore.use([Keyboard, Mousewheel]);

function Member() {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [memberList, setMemberList] = useState([]);

    useEffect(() => {
        const oauthType = getOperationSys();
            service.post('/guanwang/package', { oauth_type: oauthType, lang: I18n.language }).then(res => {
                const list = res.data.data || []
                setMemberList(list)
                global.member.data = res.data.data
            })
    }, [t])

    function onSelect(index) {
        setCurrentIndex(index)
    }

    return useMemo(

        () => (
            <div className='Member'>
                <p>{t('member.memberPrice')}</p>
                <div className='pay'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={-30}
                        mousewheel={true}
                        onSlideChange={(e) => {
                            setCurrentIndex(e.realIndex)

                        }}
                        style={{ width: '1000px', marginTop: '24px' }}
                    >
                        {

                            memberList.map((item, index) => {
                                return (
                                    <SwiperSlide className='swiper-s' key={index}>
                                        <div className='recom'>
                                            <div className={item.pack_activity_pic ? 'recom-txt' : ''}> {item.pack_activity_pic ? item.pack_activity_pic : ''}</div>
                                        </div>
                                        <div className={currentIndex === index ? 'pay-item-active' : 'pay-item'} key={index} onClick={() => onSelect(index)}>
                                            <p style={{ marginTop: "28px", fontWeight: '500' }}>{item.pack_name}</p>
                                            <p style={{ fontSize: "38px", color: "#000" }}>$ <span>{item.pack_price}</span></p>
                                            <p style={{ fontWeight: '300', textDecoration: 'line-through', color: 'rgb(95 95 115)' }}>${item.original_price}</p>
                                            <div className='discount'> {item.pack_describe}</div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>

                </div>
                {/* <div className='pay-method'>
                <p>{t('member.payfunc')}</p>
                <div className='pay-content'>
                    {

                        payList.map((item, index) => {
                            return (
                                <div className='pay-method-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }

                </div>
            </div> */}
            </div>
        ), [memberList, currentIndex, t]

    )
}
export default Member; 