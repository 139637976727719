import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { message } from 'antd';

import '../css/Bottom.css';
import mail from '../assets/m-icon.png';
import tuite from '../assets/t-icon.png';
import tg from '../assets/tg-icon.png';
import logo from '../assets/logo.png';
import service from "../utils/api";

function Bottom({ onBottom }) {
  const { t, i18n } = useTranslation();
  const [addressArr, setAddressArr] = useState([])
  useEffect(() => {
    if (global.address && global.address.data) {
      setAddressArr(global.address.data)
    } else {
      service.post('/guanwang/contact')
        .then(res => {
          const addressList = res.data.data || []
          setAddressArr(addressList)
        })
    }
  }, [])

  const connectList = [
    {
      img: mail,
      name: t('bottom.email'),
      url: addressArr.email,
    },
    {
      img: tg,
      name: t('bottom.group'),
      url: addressArr.tg,
    },
    {
      img: tuite,
      name: t('bottom.twetter'),
      url: addressArr.twitter,
    },
  ]

  function toContent(index) {

    switch (index) {
      case 1:
        onBottom(index)
        break
      case 10:
        onBottom(index)
        break
      case 2:
        window.open('./illustrate.html?lang=' + i18n.language + '&index=' + index)
        break
      case 3:
        window.open('./illustrate.html?lang=' + i18n.language + '&index=' + index)
        break
      default:
        return
    }

  }

  function toConnect(index) {
    if (index === 1) {
      window.open(addressArr.tg)
    } else if (index === 2) {
      window.open(addressArr.twitter)
    } else {
      if (copy(addressArr.email)) {
        message.info(t('content.copySuccess'), 1);
      }
    }

  }


  // function toAccount(index) {
  //   onBottom(index)
  //   console.log('toAccount>>>>>>>')
  // }

  return (
    <div className="Bottom">
      <div className='Bottom-row'>
        <div className='about-more'>

          <div className='about-more-item'>
            <p>{t('bottom.about')}</p>
            <span onClick={() => toContent(2)}>{t('bottom.service')}</span>
            <span onClick={() => toContent(3)}>{t('bottom.privacys')}</span>
          </div>

          <div className='about-more-item'>
            <p>{t('bottom.more')}</p>
            <div className='ttype'>
              <span onClick={() => toContent(1)}>{t('bottom.contactUs')}</span>
              <span style={{ marginLeft: '12px' }} onClick={() => toContent(10)}>{t('bottom.manage')}</span>
            </div>

            <div className='icon-list'>
              {connectList.map((item, index) => <img key={index} alt="" src={item.img} onClick={() => toConnect(index)}/>)}
            </div>
          </div>
        </div>

        <img style={{ width: "140px", height: '40px', marginRight: '140px' }} src={logo} alt=''></img>
      </div>

      <div className='content-layout'>{t('bottom.info1')}</div>
    </div>
  )
}


export default Bottom;