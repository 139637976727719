import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import '../css/H5sidebar.css'
import homeicon from '../assets/h5/homeicon.png';
import membericon from '../assets/h5/membericon.png';
import downloadicon from '../assets/h5/downloadicon.png';
import rebateicon from '../assets/h5/rebateicon.png';
import arrow from '../assets/h5/arrow.png';
import unselect from '../assets/h5/unselect.png';
import select from '../assets/h5/select.png';
import service from '../utils/api';
import { getOperationSys } from '..';

const languageList = [
    {
        name: "简体中文",
        code: "CN",
        value: 'zh-CN'
    },
    {
        name: "繁體中文",
        code: "TW",
        value: 'zh-TW'
    },
    {
        name: "English",
        code: "EN",
        value: 'en',
    }
]

function H5sidebar({ onPageItem, onChangeLanguage }) {
    const { t, i18n } = useTranslation();
    const [languageIndex, setLanguageIndex] = useState(0);
    
    const btnList = [
        {
            img: homeicon,
            name: t('slidebar.home')
        },
        {
            img: membericon,
            name: t('slidebar.member')
        },
        {
            img: downloadicon,
            name: t('slidebar.download')
        },
        {
            img: rebateicon,
            name: t('slidebar.rebate')
        }
    ]

    

    useEffect(() => {
        for (let index = 0; index < languageList.length; index++) {

            if (i18n.language === languageList[index].code) {
                setLanguageIndex(index)
            }

        }
    }, [i18n])


    function onChangePage(index) {
        onPageItem(index)
    }

    function onLanguage(index) {
        setLanguageIndex(index)
    }

    function onConfirm() {
        onChangeLanguage(languageList[languageIndex].code)
        message.info(t('top.change'));
        onChangePage(0)
        const oauthType = getOperationSys();
        service.post("/guanwang/package", { oauth_type: oauthType, lang: i18n.language })
            .then(res => {
                global.member = {
                    data: res.data.data
                }
            }).finally(() => {
                i18n.changeLanguage(languageList[languageIndex].value)
            })

    }

    return (
        <div className='H5sidebar'>
            <div className='btn-list'>
                {
                    btnList.map((item, index) => {
                        return (
                            <div className='btn-list-item' key={index} onClick={() => onChangePage(index)}>
                                <div className='btn-list-item-left'>
                                    <img src={item.img} alt=''></img>
                                    <p>{item.name}</p>
                                </div>
                                <img src={arrow} alt=''></img>
                            </div>
                        )
                    })
                }
            </div>
            <div className='language-list'>
                <p>{t('slidebar.selectLanguage')}</p>
                {
                    languageList.map((item, index) => {
                        return (
                            <div className='language-list-item' key={index} onClick={() => onLanguage(index)}>
                                <p>{item.name}</p>
                                <img src={languageIndex === index ? select : unselect} alt=''></img>
                            </div>
                        )
                    })
                }
                <button onClick={onConfirm}>{t('slidebar.sure')}</button>
            </div>
        </div>
    )
}

export default H5sidebar