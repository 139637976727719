import '../css/H5dwonload.css';
import selectIcon from '../assets/select-icon.png';

import ios from '../assets/ios.png'
import andriod from '../assets/andriod.png'
import macos from '../assets/macOS.png'
import tv from '../assets/tv.png'
import windows from '../assets/windows.png'
import jszx from '../assets/zksd.png';
import bxs from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import wsts from '../assets/wsts.png';
import mgg from '../assets/mgg.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';
import indexIcon from '../assets/h5/index.png';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import service from '../utils/api';

function H5dwonload() {

    const platform = global.platformOS;
    // console.log(item,"deviceName.name>>>>>>")
    const { t, i18n } = useTranslation();
    const [deviceName, setDeviceName] = useState(platform.storeName ? platform.storeName : "iOS AppStore")
    const [type, setType] = useState(platform.name ? platform.name : "ios")
    const [urlList, seturlList] = useState([])

    useEffect(() => {
        if (global.urlList && global.urlList.data) {
            seturlList(global.urlList.data)
        } else {
            service.post('/guanwang/version')
                .then(res => {
                    const lists = res.data.data || []
                    seturlList(lists)
                })
        }
    }, [])


    const infoList = [
        {
            content: t('download.fast')
        },
        {
            content: t('download.safe')
        },
        {
            content: t('download.unlimite')
        },
        {
            content: t('download.free')
        },
        {
            content: t('download.invite')
        }
    ]
    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName: "iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName: "Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName: "Mac Application"
        },
        {
            img: windows,
            name: "Windows",
            storeName: "Windows Application"
        },
        {
            img: tv,
            name: "TV",
            storeName: "TV Application"
        }

    ]

    const vipList = [
        {
            img: jszx,
            content: t('download.accele')
        },
        {
            img: bxs,
            content: t('download.unspeed')
        },
        {
            img: ddts,
            content: t('download.multi')
        },
        {
            img: wsts,
            content: t('download.speed')
        },
        {
            img: mgg,
            content: t('download.noad')
        }
    ]

    const clientList = [
        {
            img: step1,
            content: t('download.step1')
        },
        {
            img: step2,
            content: t('download.step2')
        },
        {
            img: step3,
            content: t('download.step3')
        }

    ]
    function handleDownload(item) {
        setDeviceName(item.storeName)
        setType(item.name)
    }

    function onDownload() {
        let data = ''
        for (const ite of urlList) {
            if (type.toLowerCase() === ite.version_type.toLowerCase()) {
                data = ite
            }
        }
        if (data.version_down_url) {
            window.open(data.version_down_url, "_blank")
        } else {
            message.info(data ? data.version_tips : t('download.tips'), 1);
        }
    }

    const imgs = { 'zh-CN': require('../assets/googleplay/googlePlayBadge-zh.png'), "en": require('../assets/googleplay/googlePlayBadge-en.png'), "zh-TW": require('../assets/googleplay/googlePlayBadge-tw.png') }

    return (
        <div className='H5dwonload'>
            <div className='H5dwonload-top'>
                <p>Zenon VPN</p>
                <span>{t('download.available')}</span>
                <div className='info-list'>
                    {
                        infoList.map((item, index) => {
                            return (
                                <div className='info-list-item' key={index}>
                                    <img src={selectIcon} alt=''></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            {type === "Android" ?
                <div className="google-play-container"><a href="https://play.google.com/store/apps/details?id=com.zenon.android
                        "><img className="google-play-icon" src={imgs[i18n.language]} alt=''></img></a><div className="text-download" onClick={onDownload}>
                        {t('download.googlePlay')}
                    </div></div> : <button onClick={onDownload}>{t('download.immediately') + deviceName}</button>
            }
            <div className='h5download-content'>

                <div className='platform'>
                    <p style={{ marginTop: "20px" }}>{t('download.alldevice')}</p>
                    <span>{t('download.click')}</span>
                    <div className='platform-list'>
                        {
                            downloadList.map((item, index) => {
                                return (
                                    <div className='platform-list-item' key={index} onClick={() => handleDownload(item)}>
                                        <img src={item.img} alt=''></img>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='title'>{t('download.joinvip')}</div>
                <div className='h5vip-list'>
                    {
                        vipList.map((item, index) => {
                            return (
                                <div className='h5vip-list-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='title' style={{ marginTop: '10px' }}>{t('download.how')}</div>
                <span style={{ color: 'rgba(28,28,32,0.4)', fontSize: '14px', marginTop: '0px' }}>{t('download.keep3step')}</span>
                <div className='h5vip-step'>
                    {
                        clientList.map((item, index) => {
                            return (
                                <img src={item.img} alt='' key={index}></img>
                            )
                        })
                    }
                </div>
                <img src={indexIcon} alt='' className='h5vip-step-index'></img>
                <div className='h5vip-step' style={{ justifyContent: "space-evenly" }}>
                    {
                        clientList.map((item, index) => {
                            return (
                                <p key={"p" + index}>{item.content}</p>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default H5dwonload