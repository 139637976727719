
import zksd from '../assets/zksd.png';
import fgtd from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import smll from '../assets/smll.png';
import aqfw from '../assets/aqfw.png';
import cl from '../assets/cl.png';
import ios from '../assets/ios.png'
import andriod from '../assets/andriod.png'
import macos from '../assets/macOS.png'
import tv from '../assets/tv.png'
import windows from '../assets/windows.png'
import '../css/Advantage.css';

import { useTranslation } from 'react-i18next';

function Advantage({ onDownload }) {
    const { t, i18n } = useTranslation();

    const list = [
        {
            src: zksd,
            title: t('home.list.zksd.title'),
            content: t('home.list.zksd.content')
        },
        {
            src: fgtd,
            title: t('home.list.fgtd.title'),
            content: t('home.list.fgtd.content')
        },
        {
            src: ddts,
            title: t('home.list.ddts.title'),
            content: t('home.list.ddts.content')
        },
        {
            src: smll,
            title: t('home.list.smll.title'),
            content: t('home.list.smll.content')
        },
        {
            src: aqfw,
            title: t('home.list.aqfw.title'),
            content: t('home.list.aqfw.content')
        },
        {
            src: cl,
            title: t('home.list.cl.title'),
            content: t('home.list.cl.content')
        }
    ]

    const deviceList = [
        {
            img: ios,
            name: "iOS",
            storeName: "iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName: "Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName: "Mac Application"
        },
        {
            img: windows,
            name: "Windows",
            storeName: "Windows Application"
        },
        {
            img: tv,
            name: "TV",
            storeName: "TV Application"
        }

    ]
    const imgs = { 'zh-CN': require('../assets/freevip-cn.png'), "en": require('../assets/freevip-en.png'), "zh-TW": require('../assets/freevip-tw.png') }

    function toDownload(item) {
        onDownload(item)
    }

    return (
        <div className='Advantagelayout'>
            <p>{t('home.whyselect')}</p>
            <div className='container'>
                {
                    list.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <img src={item.src} alt=''></img>
                                <p>{item.title}</p>
                                <span>{item.content}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className='device-bg'>
                <p>{t('download.alldevice')}</p>
                <span>{t('download.click')}</span>
                <div className='device-list'>
                    {
                        deviceList.map((item, index) => {
                            return (
                                <div className='device-list-item' key={index} onClick={() => toDownload(item)}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='free-tips'>{t('download.downloadtips')}</div>
                <div className='free-layout'>
                    {/* <img src={imgs[i18n.language]} alt=''></img> */}
                    <div className='free-layout-right'>
                        <p>{t('home.list.freevip.title')}</p>
                        <span>{t('home.list.freevip.content')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantage;