
import '../css/H5top.css';
import menu from '../assets/h5/menu-icon.png'
import logo from '../assets/logo.png'
import React from 'react';
// import { useTranslation} from 'react-i18next';

function H5top(props){
    // const { t } = useTranslation();

    const {code,onOpenSidebar} = props
    function onClick(){
        onOpenSidebar()
    }

    return(
        <div className='H5top'>
            <img src={logo} alt='logo'></img>
            <div className='H5top-right'  onClick={onClick}>
                {/* <p style={{color:"#fff",fontSize:"10px"}}>{t('top.test',{count:100})}</p> */}
                <div className='language-bg'>{code}</div>               
                <img src={menu} alt='menu'></img>
            </div>
        </div>
    )
}
export default H5top