
import './App.css';
import Invite from './components/Invite.js';
import Top from './components/Top.js';
import Advantage from './components/Advantage.js';
import Bottom from './components/Bottom.js';
import Connect from './components/Connect.js';
import Account from './components/Account.js';
import Member from './components/Member.js';
import Rebate from './components/Rebate.js';
import Download from './components/Download.js';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import service from './utils/api';
import { getOperationSys } from '.';

// import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  document.documentElement.scrollTop = 0
  const [tabIndex, setTabIndex] = useState('1')
  const [name, setName] = useState('iOS AppStore')
  const [bottomIndex, setBottomIndex] = useState(0)
  const [list, setList] = useState([])
  const topRef = useRef()

  useEffect(() => {
    const oauthType = getOperationSys();
    service.post("/guanwang/package", { oauth_type: oauthType, lang: i18n.language  })
      .then(res => {
        setList(res.data.data)
      })
  }, [i18n])

  function onChange(index) {
    setTabIndex(index)
    setBottomIndex(0)
  }

  function onChangeBottom(index) {
    setBottomIndex(index)
    setTabIndex(0)
  }

  function onTry(name) {
    topRef.current.changeTab('3')
    setName(name)
    // topRef.current.changeTab('3')
    // onChange('3')
  }

  function onChangeLang(lists) {
    setList(lists ? list : global.member.data || [])
    // console.log("ischange>>>>>>>>>",list)
  }

  return (
    <div className="App">
      <Top ref={topRef} onChangeTab={onChange} onChangeLang={onChangeLang}></Top>
      {/* 首页 */}
      {
        (() => {
          if (tabIndex === '1') {
            return (
              <>
                <Invite onTry={onTry}></Invite>
                <Advantage onDownload={onTry}></Advantage>
              </>
            )
          } else if (tabIndex === '2') {
            return (
              <>
                <Invite onTry={onTry} bgcolor={'#fff'}></Invite>
                <Member list={list} onChangeLang={onChangeLang}></Member>
              </>
            )
          } else if (tabIndex === '3') {
            return (
              <>
                <Download item={name}></Download>
              </>
            )

          } else if (tabIndex === '4') {
            return (
              <>
                <Rebate onDownload={onTry}></Rebate>
              </>
            )

          }
        })()
      }
      {/* 底部文字按钮交互 */}
      {
        (() => {
          // console.log(tabIndex, "tabIndex")
          if (tabIndex === 0) {
            return (
              <>

                {
                  (() => {
                    if (bottomIndex === 1) {
                      return (
                        <>
                          <Connect></Connect>
                        </>
                      )
                    } else if (bottomIndex === 10) {
                      return (
                        <>
                          <Account onAccount={onChange}></Account>
                        </>
                      )

                    }
                  })()
                }

              </>
            )
          }
        })()
      }

      <Bottom onBottom={onChangeBottom}></Bottom>
    </div>
  );
}

export default App;
