import '../css/H5home.css';
import zksd from '../assets/zksd.png';
import fgtd from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import smll from '../assets/smll.png';
import aqfw from '../assets/aqfw.png';
import cl from '../assets/cl.png';
// import freevipCN from '../assets/freevip-cn.png';
// import freevipEN from '../assets/freevip-en.png';
// import freevipTW from '../assets/freevip-tw.png';

import { useTranslation } from 'react-i18next';

function H5home({ onPage }) {
    const { t, i18n } = useTranslation();
    // console.log(i18n.language)
    const list = [
        {
            src: zksd,
            title: t('home.list.zksd.title'),
            content: t('home.list.zksd.content')
        },
        {
            src: fgtd,
            title: t('home.list.fgtd.title'),
            content: t('home.list.fgtd.content')
        },
        {
            src: ddts,
            title: t('home.list.ddts.title'),
            content: t('home.list.ddts.content')
        },
        {
            src: smll,
            title: t('home.list.smll.title'),
            content: t('home.list.smll.content')
        },
        {
            src: aqfw,
            title: t('home.list.aqfw.title'),
            content: t('home.list.aqfw.content')
        },
        {
            src: cl,
            title: t('home.list.cl.title'),
            content: t('home.list.cl.content')
        }
    ]

    const imgs ={ 'zh-CN':require('../assets/freevip-cn.png'),"en":require('../assets/freevip-en.png'),"zh-TW":require('../assets/freevip-tw.png')}

    return (
        <div className='H5home'>
            <div className='home-top'>
                <p style={{marginTop:'32px',fontSize:"28px"}}>{t('home.solution')}</p>
                <p style={{fontSize:'18px',textAlign:'center'}}>{t('home.safe')}</p>
            </div>
            <div className='h5-home-download' onClick={()=>{ onPage() }}>{t('home.btn')}</div>
            <div className='h5-home-title'>{t('home.whyselect')}</div>
            <div className='info'>
                {
                    list.map((item, index) => {
                        return (
                            <div className='list-item' key={index}>
                                <img src={item.src} alt=''></img>
                                <p>{item.title}</p>
                                <span>{item.content}</span>
                            </div>
                        )
                    })
                }

                <div className='list-item' style={{background:'none'}}>
                    {/* <img src={imgs[i18n.language]} alt=''></img> */}
                    <p>{t('home.list.freevip.title')}</p>
                    <span>{t('home.list.freevip.content')}</span>
                </div>
            </div>

        </div>
    )
}

export default H5home