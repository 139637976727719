import '../css/H5protocolPolicy.css';
function H5protocolPolicy({title,content}){

    return(
        <div className="H5protocolPolicy">
            <p style={{fontSize:"40px"}}>{title}</p>
            <p style={{fontSize:"16px"}}>{content}</p>
        </div>
    )
}
export default H5protocolPolicy