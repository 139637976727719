import axios from 'axios';

const service = axios.create({
  baseURL: 'https://api.zenonapi.com',
  timeout: 25000, 
});

service.interceptors.request.use(config => {
  console.log(config.data)
    config.data = Object.assign({}, config.data)
    return config;
})

service.interceptors.response.use(response => {
  console.log(response)
    return response;
})

export default service;

