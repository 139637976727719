
import '../css/H5Account.css';
import card from '../assets/tl_card_decentralized.gif'
import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useEffect } from "react";

import { message } from 'antd';
import service from '../utils/api';

function H5Account({ onH5Account }) {
  const { t } = useTranslation();
  const [emailVal, setEmailVal] = useState('');
  const [code, setCode] = useState('');
  const [show, setShow] = useState(false);
  const [time, setTime] = useState(0)
  const timer = useRef(null)
  //console.log(i18n.language)

  useEffect(() => {
    timer.current && clearInterval(timer.current);
    return () => timer.current && clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (time === 60) {
      timer.current = setInterval(() => setTime(time => --time), 1000)
    } else if (time <= 0) {
      timer.current && clearInterval(timer.current)
    }
    console.log('useEffect>>>>>>>')
  }, [time])



  function onEmail() {
    if (show) {
      //验证验证码
      if (emailVal === '') {
        message.error(t('account.placeholderCode'));
        return
      }
      service.post("/guanwang/checkEmailCode", { email_code: code, email: emailVal }).then(res => {
        if (res.data.data.check_email_code === 1) {
          message.info(t('account.success'));
          onH5Account(0)
        } else {
          message.error(t('account.fail'));
        }
      })
    } else {

      //验证邮箱
      if (emailVal === '') {
        message.error(t('account.placeholderEmail'));
        return
      }
      service.post("/guanwang/checkEmail", { email: emailVal } )
        .then(res => {
          if (res.data.code === 0) {
            setShow(true)
            message.info(t('account.verifySuccessful'));
          } else if (res.data.code === 1001) {
            message.error(t('account.noEmail'));
          } else if (res.data.code === 1002) {
            message.error(t('account.timeOut'));
          }
        })
    }



  }

  //获取验证码
  function onGetCode() {
    setTime(60)
    service.post("/guanwang/sendEmailCode", { email: emailVal }).then(res => {
      if (res.data.data.is_send_email === 1) {
        message.info(t('account.send'));
      }
    })
  }

  //邮箱输入
  function onChangeEmail(event) {
    setEmailVal(event.target.value);
  }

  //验证码输入
  function onChangeCode(event) {
    setCode(event.target.value);
  }



  return (
    <div className="H5Account">
      <img className='H5Account-img' src={card} alt=""></img>
      <div className='H5Account-right'>
        <p className='H5Account-p1'>{t('account.title')}</p>
        <span>{t('account.tips')}</span>
        <div className='H5Account-col'>
          <p className='H5Account-p2'>{t('account.email')}</p>
          <input className='H5Account-input' placeholder={t('account.placeholderEmail')} type='text' value={emailVal} onChange={onChangeEmail}></input>

        </div>

        {
          (() => {
            if (show) {
              return (
                <div className='H5Account-col'>
                  <p className='H5Account-p2'>{t('account.code')}</p>
                  <input className='H5Account-input' type='text' value={code} onChange={onChangeCode} maxLength={10} placeholder={t('account.placeholderCode')}></input>
                  <button className='H5code-btn' disabled={time ? true : false} onClick={onGetCode}>{time ? t('account.codeTime', { time: time }) : t('account.getCode')}</button>
                  {/* <VerifyCode></VerifyCode> */}
                </div>
              )
            }
          })()
        }

        <button className='H5Account-btn' onClick={onEmail}>{show ? t('account.ok') : t('account.step')}</button>




      </div>
    </div>
  )
}


export default H5Account;